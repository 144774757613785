import { create } from 'zustand';

import type { BookMinimalData } from '~/models/book';
import type { Category } from '~/models/category';
import type { NewCollection } from '~/models/newCollection';

export interface Personality {
  id: string;
  name: string;
  thumbnailUrl: string;
}

interface OnboardingState {
  isOnOnboarding: boolean;
  setOnOnboarding: () => void;
  setOnboardingFinished: () => void;
  // categories
  allCategories: Category[] | [];
  addAllCategories: (categories: Category[]) => void;
  selectedCategories: Category[] | [];
  selectCategory: (category: Category) => void;
  deselectCategory: (categoryId: string) => void;
  // personalities
  allPersonalities: Personality[];
  addAllPersonalities: (personalities: Personality[]) => void;
  removeAllPersonalities: () => void;
  selectedPersonalityIds: string[];
  removeAllSelectedPersonalityIds: () => void;
  selectPersonalityId: (id: string) => void;
  deselectPersonalityId: (id: string) => void;
  // collections
  allCollections: NewCollection[];
  addAllCollections: (collections: NewCollection[]) => void;
  removeAllCollections: () => void;
  selectedCollectionIds: string[];
  removeAllSelectedCollectionIds: () => void;
  selectCollectionId: (id: string) => void;
  deselectCollectionId: (id: string) => void;
  // books
  allBooks: BookMinimalData[] | [];
  addAllBooks: (books: BookMinimalData[]) => void;
  removeAllBooks: () => void;
  selectedBookIds: string[];
  selectBookId: (id: string) => void;
}

export const useOnboardingStore = create<OnboardingState>((set) => ({
  isOnOnboarding: false,
  setOnOnboarding: () => set(() => ({ isOnOnboarding: true })),
  setOnboardingFinished: () => set(() => ({ isOnOnboarding: false })),
  // categories
  allCategories: [],
  addAllCategories: (categories: Category[]) =>
    set(() => ({ allCategories: categories })),
  selectedCategories: [],
  selectCategory: (category: Category) =>
    set((state) => ({
      selectedCategories: [...state.selectedCategories, category],
    })),
  deselectCategory: (categoryId: string) =>
    set((state) => ({
      selectedCategories: state.selectedCategories.filter(
        (c) => c.id !== categoryId
      ),
    })),
  // personalities
  allPersonalities: [],
  addAllPersonalities: (personalities) =>
    set((state) => ({
      allPersonalities: [...state.allPersonalities, ...personalities],
    })),
  removeAllPersonalities: () =>
    set(() => ({
      allPersonalities: [],
    })),
  selectedPersonalityIds: [],
  removeAllSelectedPersonalityIds: () =>
    set(() => ({
      selectedPersonalityIds: [],
    })),
  selectPersonalityId: (id) =>
    set((state) => ({
      selectedPersonalityIds: [...state.selectedPersonalityIds, id],
    })),
  deselectPersonalityId: (id) =>
    set((state) => ({
      selectedPersonalityIds: state.selectedPersonalityIds.filter(
        (personalityId) => personalityId !== id
      ),
    })),
  // collections
  allCollections: [],
  addAllCollections: (collections) =>
    set((state) => ({
      allCollections: [...state.allCollections, ...collections],
    })),
  removeAllCollections: () =>
    set(() => ({
      allCollections: [],
    })),
  selectedCollectionIds: [],
  removeAllSelectedCollectionIds: () =>
    set(() => ({
      selectedCollectionIds: [],
    })),
  selectCollectionId: (id) =>
    set((state) => ({
      selectedCollectionIds: [...state.selectedCollectionIds, id],
    })),
  deselectCollectionId: (id) =>
    set((state) => ({
      selectedCollectionIds: state.selectedCollectionIds.filter(
        (collectionId) => collectionId !== id
      ),
    })),
  // books
  allBooks: [],
  addAllBooks: (books) => set(() => ({ allBooks: books })),
  removeAllBooks: () =>
    set(() => ({
      allBooks: [],
    })),
  selectedBookIds: [],
  selectBookId: (id) =>
    set((state) => ({
      selectedBookIds: [...state.selectedBookIds, id],
    })),
}));

export function useIsOnOnboarding() {
  return useOnboardingStore((state) => state.isOnOnboarding);
}
export function useSetOnOnboarding() {
  return useOnboardingStore((state) => state.setOnOnboarding);
}
export function useSetOnboardingFinished() {
  return useOnboardingStore((state) => state.setOnboardingFinished);
}

// categories
export function useAllCategories() {
  return useOnboardingStore((state) => state.allCategories);
}
export function useAddAllCategories() {
  return useOnboardingStore((state) => state.addAllCategories);
}
export function useSelectedCategories() {
  return useOnboardingStore((state) => state.selectedCategories);
}
export function useSelectCategory() {
  return useOnboardingStore((state) => state.selectCategory);
}
export function useDeselectCategory() {
  return useOnboardingStore((state) => state.deselectCategory);
}

// personalities
export function useAllPersonalities() {
  return useOnboardingStore((state) => state.allPersonalities);
}
export function useAddAllPersonalities() {
  return useOnboardingStore((state) => state.addAllPersonalities);
}
export function useRemoveAllPersonalities() {
  return useOnboardingStore((state) => state.removeAllPersonalities);
}
export function useSelectedPersonalityIds() {
  return useOnboardingStore((state) => state.selectedPersonalityIds);
}
export function useRemoveAllSelectedPersonalityIds() {
  return useOnboardingStore((state) => state.removeAllSelectedPersonalityIds);
}
export function useSelectPersonalityId() {
  return useOnboardingStore((state) => state.selectPersonalityId);
}
export function useDeselectPersonalityId() {
  return useOnboardingStore((state) => state.deselectPersonalityId);
}

// collections
export function useAllCollections() {
  return useOnboardingStore((state) => state.allCollections);
}
export function useAddAllCollections() {
  return useOnboardingStore((state) => state.addAllCollections);
}
export function useRemoveAllCollections() {
  return useOnboardingStore((state) => state.removeAllCollections);
}
export function useSelectedCollectionIds() {
  return useOnboardingStore((state) => state.selectedCollectionIds);
}
export function useRemoveAllSelectedCollectionIds() {
  return useOnboardingStore((state) => state.removeAllSelectedCollectionIds);
}
export function useSelectCollectionId() {
  return useOnboardingStore((state) => state.selectCollectionId);
}
export function useDeselectCollectionId() {
  return useOnboardingStore((state) => state.deselectCollectionId);
}

// books
export function useAllBooks() {
  return useOnboardingStore((state) => state.allBooks);
}
export function useAddAllBooks() {
  return useOnboardingStore((state) => state.addAllBooks);
}
export function useRemoveAllBooks() {
  return useOnboardingStore((state) => state.removeAllBooks);
}
export function useSelectedBookIds() {
  return useOnboardingStore((state) => state.selectedBookIds);
}
export function useSelectBookId() {
  return useOnboardingStore((state) => state.selectBookId);
}
